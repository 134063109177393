.dropdown-item img {
  margin-right: 24px;
  width: 64px;
}

.jumbotron {
  padding: 24px !important;
}

.toast {
  position: fixed;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  z-index: 100;
}

.toast-body {
  cursor: pointer;
  text-align: center;
}
